<template>
  <div class="control">
    <div class="header flex flex-center">设备控制</div>
    <div class="content">
      <div class="block">
        <div class="block-title">设备开关</div>
        <split-checkbox :options="Object.values(DeviceUtils.Command.SWITCH)" v-model="formData.SWITCH"></split-checkbox>
      </div>
      <div class="block">
        <div class="block-title">运行模式</div>
        <split-checkbox :options="Object.values(DeviceUtils.Command.MODE)" v-model="formData.MODE" :valueName="'name'"></split-checkbox>
      </div>
      <div class="block">
        <div class="block-title">风速调节</div>
        <split-checkbox :options="Object.values(DeviceUtils.Command.SPEED)" v-model="formData.SPEED"></split-checkbox>
      </div>
      <div class="block">
        <div class="block-title flex flex-cross-center">
          <span>温度调节</span>
          <base-switch class="temp-enable" v-model="tempEnable" :disabled="tempDisabled"></base-switch>
          <span class="tip">温度调节指令使能：{{tempEnable?'开':'关'}}</span>
        </div>
        <base-input v-model="formData.TEMP" :disabled="!tempEnable"></base-input>
      </div>
<!--      <div class="row flex flex-between-center">-->
<!--        <div class="block-title">睡眠模式</div>-->
<!--        <div class="flex flex-cross-center">-->
<!--&lt;!&ndash;          <span class="status">{{formData.SLEEP?'已开启':'已关闭'}}</span>&ndash;&gt;-->
<!--          <split-checkbox :options="Object.values(DeviceUtils.Command.SLEEP)" v-model="formData.SLEEP"></split-checkbox>-->
<!--&lt;!&ndash;          <base-switch v-model="formData.SLEEP"></base-switch>&ndash;&gt;-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="row flex flex-between-center">-->
<!--        <div class="block-title">电加热设置</div>-->
<!--        <div class="flex flex-cross-center">-->
<!--&lt;!&ndash;          <span class="status">{{formData.HEAT?'已开启':'已关闭'}}</span>&ndash;&gt;-->
<!--          <split-checkbox :options="Object.values(DeviceUtils.Command.HEAT)" v-model="formData.HEAT"></split-checkbox>-->
<!--&lt;!&ndash;          <base-switch v-model="formData.HEAT"></base-switch>&ndash;&gt;-->
<!--        </div>-->
<!--      </div>-->
      <div class="row flex-between-center" v-if="canUserControl">
        <div class="block-title">用户可控</div>
        <div class="flex flex-cross-center">
<!--          <span class="status">{{formData.USERCONTROL?'已开启':'已关闭'}}</span>-->
         <split-checkbox :options="Object.values(DeviceUtils.Command.USERCONTROL)" v-model="formData.USERCONTROL"></split-checkbox>
          <!-- <base-switch v-model="formData.isControl"></base-switch> -->
        </div>
      </div>
    </div>
    <div class="footer flex flex-center">
      <div class="cancel btn flex flex-center" @click="onCancel">重置指令</div>
      <div class="confirm btn flex flex-center" @click="onConfirm">发送指令</div>
    </div>
    <!-- <base-confirm :show.sync="showConfirm" @confirm="confirm" title="操作提示">{{confirmMessage}}</base-confirm> -->
  </div>
</template>

<script>
  // import BaseConfirm from '../components/base-confirm'
  import SplitCheckbox from '@/components/split-checkbox'
  // import GroupCheckbox from '../components/group-checkbox'
  import DeviceUtils from '@/utils/deviceUtil'
  import BaseInput from '@/components/base-input'
  import Command from '@/utils/command'
  import BaseSwitch from '@/components/base-switch'
  // import BaseSwitch from '../components/base-switch'
  const preventMove = e => {
    e.preventDefault()
    return false
  }
  export default {
    name: 'deviceControl',
    props: ['id', 'sn'],
    components: { BaseSwitch, BaseInput, SplitCheckbox },

    data () {
      return {
        DeviceUtils,
        tempEnable: false,
        tempDisabled: false,
        canUserControl: true,
        // showConfirm: false,
        confirmMessage: '',
        device: {},
        formData: {
          sn: null,
          deviceId: null,
          SWITCH: null,
          TEMP: 25,
          MODE: null,
          SPEED: null,
          SLEEP: null,
          HEAT: null,
          USERCONTROL: null
        }
      }
    },
    mounted () {
      this.formData.deviceId = this.id
      if (['8002248', '8002389', '8002826'].includes(this.sn.substring(3, 10))) {
        this.canUserControl = false
      } else {
        this.canUserControl = true
      }
      // this.$axios.get(`/h5/device/airconditioner/${this.id}`).then(res => {
      //   console.log('res', res)
      //   this.device = res
      //   this.tempEnable = false
      //   this.formData.SWITCH = res.switchStateEnum ? res.switchStateEnum.state : null
      //   this.formData.TEMP = res.temperature || null
      //   this.formData.MODE = (res.autoStateEnum && (res.autoStateEnum.state === 1)) ? DeviceUtils.Command.MODE.AUTO.name : (res.modeStateEnum ? res.modeStateEnum.name : null)
      //   this.formData.SPEED = res.speedStateEnum ? res.speedStateEnum.state : null

      //   if (res.lockStateEnum) {
      //     this.formData.USERCONTROL = res.lockStateEnum.state === 1 ? DeviceUtils.Command.USERCONTROL.OFF.value : DeviceUtils.Command.USERCONTROL.ON.value
      //   } else if (res.powerSwitch) {
      //     this.formData.USERCONTROL = res.powerSwitch.state === 1 ? DeviceUtils.Command.USERCONTROL.ON.value : DeviceUtils.Command.USERCONTROL.OFF.value
      //   } else {
      //     this.formData.USERCONTROL = null
      //   }
      // })

      // document.addEventListener('touchmove', preventMove, { passive: false })
    },
    destroyed () {
      // document.removeEventListener('touchmove', preventMove)
      this.onCancel()
    },
    watch: {
      'formData.SWITCH': {
        handler (newVal, oldVal) {
          if (newVal === DeviceUtils.Command.SWITCH.ON.value) { // 开机
            DeviceUtils.Command.SWITCH.OFF.settable = true
            // 关机状态下除开机和用户可控外 其余都不能控制
            for (const mode of Object.values(DeviceUtils.Command.MODE)) {
              mode.settable = true
            }
            for (const speed of Object.values(DeviceUtils.Command.SPEED)) {
              speed.settable = true
            }
            this.tempDisabled = false
          } else if (newVal === DeviceUtils.Command.SWITCH.OFF.value) {
            DeviceUtils.Command.SWITCH.ON.settable = true
            // 关机状态下除开机和用户可控外 其余都不能控制
            for (const mode of Object.values(DeviceUtils.Command.MODE)) {
              mode.settable = false
              this.formData.MODE = null
            }
            for (const speed of Object.values(DeviceUtils.Command.SPEED)) {
              speed.settable = false
              this.formData.SPEED = null
            }
            this.tempEnable = false
            this.tempDisabled = true
          }
        }
      },
      'formData.MODE': {
        handler (newVal, oldVal) {
          // 送风模式
          if (newVal && (newVal === DeviceUtils.Command.MODE.BLOW.name)) { // 送风模式 风速为中风 禁用自动和强劲风
            this.formData.SPEED = 3
            for (const speed of Object.values(DeviceUtils.Command.SPEED)) {
              speed.settable = true
            }
            DeviceUtils.Command.SPEED.AUTO.settable = false
            DeviceUtils.Command.SPEED.POWER.settable = false
            this.tempEnable = false
            this.tempDisabled = true
          } else if (newVal && (newVal !== DeviceUtils.Command.MODE.BLOW.name)) { // 非送风模式
            for (const speed of Object.values(DeviceUtils.Command.SPEED)) {
              speed.settable = true
            }
            this.formData.SPEED = null
            this.tempDisabled = false
          }
        },
      }
    },
    methods: {
      confirm () {
        this.$router.back()
      },
      onCancel () {
        this.formData = {
          sn: this.device.sn,
          deviceId: this.id,
          SWITCH: null,
          TEMP: 25,
          MODE: null,
          SPEED: null,
          USERCONTROL: null
        }
        this.tempEnable = false
        this.tempDisabled = false
        DeviceUtils.Command.SWITCH.OFF.settable = true
        DeviceUtils.Command.SWITCH.ON.settable = true
        for (const mode of Object.values(DeviceUtils.Command.MODE)) {
          mode.settable = true
        }
        for (const speed of Object.values(DeviceUtils.Command.SPEED)) {
          speed.settable = true
        }
      },
      onConfirm () {
        console.log('formData', this.formData)
        const commands = [
          Object.values(DeviceUtils.Command.SWITCH).find(s => s.value === this.formData.SWITCH),
          Object.values(DeviceUtils.Command.MODE).find(s => s.name === this.formData.MODE),
          Object.values(DeviceUtils.Command.SPEED).find(s => s.value === this.formData.SPEED),
          // Object.values(DeviceUtils.Command.SLEEP).find(s => s.value === this.formData.SLEEP),
          // Object.values(DeviceUtils.Command.HEAT).find(s => s.value === this.formData.HEAT),
          // Object.values(DeviceUtils.Command.USERCONTROL).find(s => s.value === this.formData.USERCONTROL)
        ]
        if (this.tempEnable) {
          commands.push(Command.builder().setValue((this.formData.TEMP).toFixed(1)).setName('温度').setCode(DeviceUtils.CommandCode.TEMP))
        }
        if (this.formData.MODE === DeviceUtils.Command.MODE.WARM.name) { // 制热模式  带上电加热
          commands.push(DeviceUtils.Command.HEAT.ON)
        } else if (this.formData.MODE && (this.formData.MODE !== DeviceUtils.Command.MODE.WARM.name) && (this.formData.MODE !== DeviceUtils.Command.MODE.AUTO.name)) {
          commands.push(DeviceUtils.Command.HEAT.OFF)
        }
        if (this.formData.MODE && (this.formData.MODE !== DeviceUtils.Command.MODE.AUTO.name)) { // 其他模式  关闭全自动
          commands.push(Command.builder().setValue(0).setName(DeviceUtils.Command.MODE.AUTO.name).setCode(DeviceUtils.CommandCode.AUTO))
        }
        if (this.formData.USERCONTROL === DeviceUtils.Command.USERCONTROL.ON.value) { // 特殊处理  取反，与PC后台保持一致
          commands.push(Command.builder().setValue(1).setName('已锁定').setCode(DeviceUtils.CommandCode.LOCK))
        }
        if (this.formData.USERCONTROL === DeviceUtils.Command.USERCONTROL.OFF.value) {
          commands.push(Command.builder().setValue(0).setName('未锁定').setCode(DeviceUtils.CommandCode.LOCK))
        }


        this.batchCommands = commands.filter(command => command !== undefined)
        if (!this.batchCommands || this.batchCommands.length < 1) {
          this.$toast.show('请选择要执行的任务')
          return
        }
        this.$axios.post(`/h5/device/exec/${this.formData.deviceId}`, this.batchCommands).then(res => {
          if (res) {
            this.confirmMessage = '指令发送成功，请稍后刷新列表状态'
            this.onCancel()
            this.$router.back()
          } else {
            this.confirmMessage = '指令下发失败'
          }
          this.$toast.show(this.confirmMessage)
        })
      }
    }
  }
</script>

<style scoped lang="stylus">
.control
  background #fff
  .header
    height 80rpx
    background rgb(45,41,115)
    font(32,48,#fff)
  .content
    height calc(100% - 192rpx)
    overflow-y auto
    padding 28rpx 32rpx
    box-sizing border-box
    .temp-enable
      margin: 0 22rpx
    .tip
      font-size 28rpx
      color rgba(0,0,0,.45)
    .row
      /*margin 40rpx 0*/
      .status
        font(28,44,$subtext)
        margin-right 20rpx
  .footer
    position fixed
    bottom 0
    left 0
    width 100%
    height 120rpx
    background #fff
    transition: all 0.5s
    box-shadow: 0 -4rpx 8rpx rgba(0,0,0,0.05);
    .btn
      width 280rpx
      height 80rpx
      font-size 32rpx
      border-radius 20rpx
      &.cancel
        background #edeff2
        color $menu
        margin-right 40rpx
      &.confirm
        background #1979FF
        color #fff
</style>
